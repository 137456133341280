import React, { useState } from "react";
import {
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { MessageAlert } from "../../../components/MessageAlert";
import { UploadExcel } from "../../../components/Excel/UploadExcel";
import { Alert } from "../../../../components/Alert";

const initialAlertError = {
  title: "",
  open: false,
  messages: [],
  icon: "",
  buttonClose: true,
};

export default function UploadMassiveExcel({mutateApprovationMassive, setUploadFinish, dataAllEmployee}) {
  const [dataToUpload, setDataToUpload] = useState();
  const [alert, setAlert] = useState(initialAlertError);
  const [isOpen, setIsOpen] = useState(false);

  const handleUploadExcel = (data) => {
    setDataToUpload(data);
  };

  const onErrorHeaders = (dataError) => {
    setAlert({
      ...alert,
      open: true,
      title: dataError.title,
      messages: [dataError.messages],
    });
  }

  const handleSendData = () => {
    setIsOpen(true);

    mutateApprovationMassive(dataToUpload, {
      onSuccess: () => {
        setIsOpen(false);
        setUploadFinish(true);
      },
      onError: () => {
        setIsOpen(false);
        setAlert({
          ...alert,
          open: true,
          title: "Error",
          messages: ['El archivo presenta un error. Porfavor revisar y rectificar'],
        });
      },
    })
  }

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleConfirmationAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <Stack spacing={2}>
      <MessageAlert 
          message={
            <Box sx={{display:"flex"}}>
              <Typography>
                <b>Este archivo tiene {dataAllEmployee.metadata.total_employee} solicitudes. </b>
                Asegurate que hayan {dataAllEmployee.metadata.total_employee} solicitudes aprobadas y/o rechazadas en la plantilla.
              </Typography>
            </Box>
          }
        />
        <Box>
          <UploadExcel
            handleUploadExcel={handleUploadExcel}
            placeholder="Guardar"
            replaceHeaders={["numberRut", "name", "type", "action"]}
            validateHeaders={["RUT", "Nombre", "Tipo", "Aprobado/Rechazado"]}
            onErrorHeaders={onErrorHeaders}
            handleSendExcel={handleSendData}
            maxLength={dataAllEmployee.metadata.total_employee}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        </Box>
        <Alert
          open={alert?.open}
          handleConfirmation={handleConfirmationAlert}
          title={alert?.title}
          messages={alert?.messages}
          icon={alert?.icon}
        />
    </Stack>
  );
}
