import { Box, Typography } from '@mui/material'
import { ReactComponent as WarningIcon } from "../../assets/images/warning.svg"

export function MessageAlert({message}) {
  return (
    <Box 
      sx={{ 
        background:"#FEECD2",
        borderRadius: 4,
        border: '0.6px solid #FF7E44',
        display: 'flex',
        p: 2,
      }}>
      
      <WarningIcon sx={{ color: '#FF7E44', width: '32px', height: '28px'}}/>
      <Typography 
        variant="body1"
        fontFamily={"Lato"}
        fontWeight={800}
        fontSize={"14px"}
        lineHeight={"18.9px"}
        sx={{ mt: 0.5, ml: 2, color: '#0032A0' }}
      >
        {message}
      </Typography>
    </Box>
  )
}
