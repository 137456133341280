import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { AddCircleOutlined, DeleteOutlined, Edit } from "@mui/icons-material";
import { ProtectedComponent } from "../../ProtectedComponent";
import { Table } from "../../../v2/components/Table";
import { SearchBar } from "../../SearchBar";
import {
  PROVIDER_APPLY_ADD,
  PROVIDER_APPLY_EDIT,
  PROVIDER_APPLY_DELETE,
} from "../../../constants/functionalities";
import { EmployeeStatus } from "../../../constants/status";
import { AddNew } from "../AddNew";
import fleetImg from "../../../assets/images/datos-vehiculos.png";
import styles from "../../../v2/components/TabsAddon/TabsAddon.module.css";
import { ChipStatus } from "../../../v2/components/Chip";

const headCells = [
  {
    id: "plate",
    label: "Patente",
    sort: {
      id: "fleetIds.plate",
      default: true,
      order: "asc",
    },
  },
  {
    id: "courierDni",
    label: "DNI Conductor",
    sort: {
      id: "fleetIds.courierDni",
    },
  },
  {
    id: "peonetaDni",
    label: "DNI Peoneta",
    sort: {
      id: "fleetIds.peonetaDni",
    },
  },
  {
    id: "Estado",
    label: "status",
    sort: {
      id: "fleetIds.status",
    },
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export function FleetTable({
  fleetPaginated,
  onSearch,
  handleCreate,
  handleDelete,
  handleEdit,
  onChangeFilters,
  showTable,
  availability,
}) {
  const { TableContainer } = Table(
    headCells,
    fleetPaginated.metadata,
    onChangeFilters
  );

  if (!showTable) {
    return (
      <AddNew
        handleCreate={handleCreate}
        title={"Aún no tienes vehículos en tu lista"}
        description={"Elige una de las opciones para registrar los vehículos."}
        button={"Agregar un vehículo"}
        image={fleetImg}
      />
    );
  }
  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.tabAddon}
    >
      <Box sx={{ display: "flex" }}>
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="buscar"
          onSearch={onSearch}
        />
      </Box>

      <ProtectedComponent functionality={[PROVIDER_APPLY_ADD]}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlined color="inherit" />}
          disabled={!availability}
          onClick={handleCreate}
        >
          Agregar Nuevo
        </Button>
      </ProtectedComponent>
    </Stack>
  );

  return (
    <>
      <Stack spacing={2}>
        <TableContainer addons={[TabsAddon]} withPagination>
          <TableBody>
            {fleetPaginated.vehicles?.map((fleet) => (
              <TableRow key={fleet.id} hover tabIndex={-1}>
                <TableCell>{fleet.plate}</TableCell>
                <TableCell>
                  {fleet.courierDni ?? "Sin conductor asignado"}
                </TableCell>
                <TableCell>
                  {fleet.peonetaDni ?? "Sin peoneta asignado"}
                </TableCell>
                <TableCell>
                  <ChipStatus
                    label={EmployeeStatus[fleet.status]}
                    setcolor={fleet.status}
                  />
                </TableCell>
                <TableCell>
                  <Stack direction="row">
                    <ProtectedComponent functionality={[PROVIDER_APPLY_EDIT]}>
                      <IconButton
                        onClick={() => handleEdit(fleet)}
                        disabled={fleet.status !== EmployeeStatus.DRAFT}
                      >
                        <Edit />
                      </IconButton>
                    </ProtectedComponent>
                    <ProtectedComponent functionality={[PROVIDER_APPLY_DELETE]}>
                      <IconButton
                        onClick={() => handleDelete(fleet)}
                        disabled={fleet.status !== EmployeeStatus.DRAFT}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </ProtectedComponent>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Stack>
    </>
  );
}
