import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getReportRequest(filters) {
  const { axiosInstance } = useApi();
  const baseUrl = `${REQUEST_TRACKER_URI}/report/security`;
  const url = buildUrlWithQueryParams(baseUrl, filters);
  const response = await axiosInstance.get(url);

  return response.data;
}

export function useReportRequest(filters) {
  return useQuery(["reportRequests", filters], () => getReportRequest(filters));
}
