import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { EMPLOYEE_REPORT_URI } from "../../../../constants/api";

export async function getReportAllEmployee() {
  const { axiosInstance } = useApi();
  const url = `${EMPLOYEE_REPORT_URI}/all`;
  const response = await axiosInstance.get(url);

  return response.data;
}

export function useReportAllEmployee(filters) {
  return useQuery(["reportAllEmployee", filters], () => getReportAllEmployee());
}
