import React, { useState } from "react";
import {
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { PaperWhite } from "../../../components/Papers";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useReportAllEmployee } from "../../services/hooks/Security/useReportAllEmployee";
import DownloadExcel from "./components/downloadExcel";
import { UploadFinish } from "./components/uploadFinish";
import UploadMassiveExcel from "./components/uploadMassiveExcel";
import { useApprovationMassive } from "../../services/hooks/Security/useApprovationMassive";


export default function SecurityApplicationsMassive() {
  const [uploadFinish, setUploadFinish] = useState(false);
  const { data: dataAllEmployee, isLoading: isLoadingDataAllEmployee } = useReportAllEmployee();
  const { mutate: mutateApprovationMassive } = useApprovationMassive();

  if (uploadFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Postulaciones`}
        </Typography>
        <UploadFinish
          goBack={() => setUploadFinish(false)}
          goBackTitleButton={`Volver a Carga masiva`}
        />
      </Stack>
    );
  }

  if (isLoadingDataAllEmployee) return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Postulaciones
      </Typography>
      <PaperWhite direction="row" spacing={2}>
        <DownloadExcel
          title={'Aprobación de postulaciones de Conductores, Peonetas y Proveedores'}
          content={`Tienes ${dataAllEmployee?.metadata?.total_courier} postulaciones de Conductores,
          ${dataAllEmployee?.metadata?.total_peoneta} de Peonetas y
          ${dataAllEmployee?.metadata?.total_provider} de Proveedores pendientes de aprobación.`}
          dataAllEmployee={dataAllEmployee}
        />
        <Divider sx={{ pt: 1, pb: 2, mb: 3 }} />
        <UploadMassiveExcel 
          mutateApprovationMassive={mutateApprovationMassive} 
          setUploadFinish={setUploadFinish} 
          dataAllEmployee={dataAllEmployee}
        />
      </PaperWhite>
    </Stack>
  );
}
