import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";


export function useCreateReplaceDocument() {
    const { axiosInstance } = useApi();
    const queryClient = useQueryClient();

    return useMutation((data) => axiosInstance.post('/replaceDocument', data, {
        baseURL: REQUEST_TRACKER_URI,
    },
    ), 
    {
        onSuccess: () => {
            setTimeout(() => {
                queryClient.refetchQueries('employeesByProvider');
                queryClient.refetchQueries('fleetListByProvider');
                queryClient.invalidateQueries('getEmployee');
                queryClient.invalidateQueries('fleetById');
            }, 1000);
        }
    });
}
