import { Button, Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { ChipStatus } from "../../../components/Chip";
import { formatDate } from "../../../../utils/formatValues";
import {
  EMPLOYEE_TYPE_ENUM,
  RequestStatus,
  VACANCY_TYPE_ENUM,
} from "../../../../constants/status";
import { SearchBar } from "../../../../components/SearchBar";
import { Table } from "../../../components/Table";
import { requestTypes } from "../../../constants/requests";
import { ReactComponent as InfoButton } from "../../../assets/images/infobutton.svg";
import { TableButton } from "../../../components/Button/TableButton";
import { DownloadExportData } from "../../../components/Excel/Excel";
import { maskRUT } from "../../../../utils";

export const RequestTable = ({
  data = [],
  onInfo,
  metadata,
  entityType,
  getValue,
  clearFilters,
  setFilters,
}) => {
  const isPeonetaTable = entityType === EMPLOYEE_TYPE_ENUM.PEONETA;
  const isFleetTable = entityType === VACANCY_TYPE_ENUM.FLEET;
  const fleetCells = [
    {
      id: "plate",
      label: "Patente",
      disableSorting: false,
      exportExcel: true,
    },
    {
      id: "fleetType",
      label: "Tipo",
      disableSorting: false,
      exportExcel: true,
    },
  ];
  const employeeCells = [
    {
      id: `numberRut`,
      label: "Rut",
      exportExcel: true,
    },
  ];
  const headCells = [
    {
      id: "base",
      label: "Base",
      disableSorting: false,
      exportExcel: true,
    },
    {
      id: "type",
      label: "Tipo de Solicitud",
      disableSorting: true,
      exportExcel: true,
    },
    {
      id: "statusTranslate",
      label: "Estado",
      disableSorting: true,
      exportExcel: true,
    },
    {
      id: "requestDate",
      label: "Fecha de Solicitud",
      sort: {
        id: "created.at",
        order: "asc",
      },
      exportExcel: true,
    },
    {
      id: "providerName",
      label: "Proveedor",
      disableSorting: false,
      exportExcel: true,
    },
    {
      id: "providerNumerRut",
      label: "Rut Proveedor",
      disableSorting: false,
      exportExcel: true,
    },
    ...(isFleetTable ? fleetCells : employeeCells),
    {
      id: "",
      label: "Acciones",
      disableSorting: false,
    },
  ];

  const onChangeFilters = (newFilters) => {
    if (!newFilters) return;
    setFilters(newFilters);
  };

  const { TableContainer, setPage } = Table(
    headCells,
    {
      ...metadata,
      page: getValue("page", 1),
      limit: getValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    onChangeFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const dataRequest = data.map((request) => {
    const dni = isPeonetaTable
      ? request?.requested?.for?.peonetaDni
      : request?.requested?.for?.courierDni;
    const complementaryEmployee = isPeonetaTable
      ? request?.requested?.for?.courierDni
      : request?.requested?.for?.peonetaDni;
    return {
      id: request.id,
      requestType: request.type,
      type: requestTypes[request.type],
      statusTranslate: RequestStatus[request.status],
      status: request.status,
      requestDate: formatDate(
        request.created?.at ?? request.requested?.at,
        "DD-MM-YYYY"
      ),
      base: request?.requested?.for?.base?.code ?? "-",
      providerName: request?.requested?.for?.provider?.name ?? "-",
      providerNumerRut: request?.requested?.for?.provider?.numberRut ?? "-",
      ...(isFleetTable
        ? {
            fleetPlate: request?.requested?.for?.plate,
            fleetType: request?.requested?.for?.subType ?? "-",
            courierDni: request?.requested?.for?.courierDni ?? "-",
            peonetaDni: request?.requested?.for?.peonetaDni ?? "-",
          }
        : {
            numberRut: dni,
            [`${isPeonetaTable ? "courier" : "peoneta"}`]:
              complementaryEmployee,
            fleetPlate: request?.requested?.for?.plate ?? "-",
          }),
    };
  });

  const TableAddon = () => (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="Buscar"
          onSearch={handleSearch}
          search={getValue("search", "")}
        />
        <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
          <Button variant="contained" onClick={clearFilters} size="medium">
            Limpiar filtros
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <DownloadExportData
          data={dataRequest}
          columns={headCells}
          nameFile={`solicitudes-${entityType}`}
          label="Descargar Archivo"
        />
      </Stack>
    </Stack>
  );
  return (
    <TableContainer addons={[TableAddon]} withPagination>
      <TableBody>
        {dataRequest?.map((request) => {
          return (
            <TableRow hover tabIndex={-1} key={request.id}>
              <TableCell>{request.base}</TableCell>
              <TableCell>{request.type}</TableCell>
              <TableCell>
                <ChipStatus
                  label={request.statusTranslate}
                  setcolor={request.status}
                />
              </TableCell>
              <TableCell>{request.requestDate}</TableCell>
              <TableCell>{request.providerName}</TableCell>
              <TableCell>{maskRUT(request.providerNumerRut)}</TableCell>
              {isFleetTable ? (
                <>
                  <TableCell>{request?.fleetPlate}</TableCell>
                  <TableCell>{request?.fleetType ?? "-"}</TableCell>
                </>
              ) : (
                <>
                  <TableCell>{maskRUT(request?.numberRut)}</TableCell>
                </>
              )}
              <TableCell>
                <Stack direction="row">
                  <TableButton onClick={() => onInfo(request)}>
                    <InfoButton />
                  </TableButton>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};
