import React from "react";
import {
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download.svg"
import { urlDownload } from "../../../helper/files";

export default function DownloadExcel({title, content, dataAllEmployee}) {

  const handleDownloadReport = () => {
    urlDownload(dataAllEmployee.fileUrl);
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2} sx={{mb:2}}>
        <Typography variant="h1" fontFamily={"Montserrat"} fontWeight={700} fontSize={"18px"} lineHeight={"21.94px"}>
          {title}
        </Typography>
        <Typography variant="body1" fontFamily={"Lato"} fontWeight={800} fontSize={"16px"} lineHeight={"21.6px"}>
          {content}
        </Typography>
        <Typography variant="body1" fontFamily={"Lato"} fontWeight={400} fontSize={"14px"} lineHeight={"18.9px"} >
          1. Descarga el archivo<br/>
          2. Procesa el archivo en la plataforma plataforma de revisión<br/>
          3. Sube el archivo con los resultados obtenidos.<br/>
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Button 
            variant='outlined'
            disabled={dataAllEmployee.metadata.total_employee === 0}
            startIcon={<DownloadIcon />}
            onClick={handleDownloadReport}
            sx={{
              border:"1.5px solid #FF7E44", 
              borderRadius:"12px", 
              width:"200px", 
              height:"40px",
              padding:"10px, 16px, 10px, 16px"
            }}>
              {"Descargar Archivo"}
          </Button>
      </Stack>
    </Stack>
  );
}
