import {
  Routes as Router,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useEffect, useState } from "react";
import { FormOffer, ListOffers, OfferDetails } from "../pages/Offers";
import { ListEmployee } from "../pages/Employee";
import { CreateProfile } from "../pages/Profile";
import { OfferApply } from "../pages/Apply";
import {
  FeaturesVehicles,
  TypeVehicles,
  FormFeaturesVehicles,
  FormTypeVehicles,
} from "../pages/Vehicles";
import { FormDocumentTypes, Types } from "../pages/Docs";
import { Documents, FormDocuments } from "../pages/Docs";
import { FormTemplates, Templates } from "../pages/Docs";
import { ProvidersRequests } from "../v2/pages/MyRequests";
import { Bases, FormBases } from "../pages/Bases";
import { Error404 } from "../pages/404";

import { ProtectedRoute } from "../components/ProtectedRoute";

import {
  COURIER_LIST,
  COURIER_EDIT,
  PEONETA_LIST,
  PEONETA_EDIT,
  FLEET_LIST,
  PROVIDER_LIST,
  OFFER_LIST,
  APPLY_LIST,
  REQUEST_LIST,
  PROVIDER_REQUESTS_LIST,
  CONFIG_FLEET,
  CONFIG_DOCUMENTS,
  CONFIG_BASES,
  SECURITY_APPLY_LIST,
  SECURITY_REQUEST_LIST,
  PROVIDER_APPLY_LIST,
  PROVIDER_COURIER_LIST,
  PROVIDER_PEONETA_LIST,
  PROVIDER_FLEET_LIST,
  PROVIDER_PROFILE,
  ALL_PERMISSIONS,
} from "../constants/functionalities";

import { useMyProvider } from "../services/hooks/Providers/useMyProvider";
import { ROLES } from "../constants/roles";
import { Profile } from "../pages/Profile/Profile";
import { useAuth } from "../hook/useAuth";
import { NewFleetStep } from "../components/Forms/Fleet";
import { FinishApply } from "../components/Forms/Finish";
import ApplyOutlet from "../v2/components/Outlet/ApplyOutlet";
import { RejectedApply } from "../v2/pages/RejectedApply";
import ManageEmployee from "../pages/Employee/ManageEmployee";
import ListApply from "../v2/pages/AppliesList";
import Security from "../v2/pages/Security";
import SecurityApplicationsMassive from "../v2/pages/Security/applicationMassive";
import {
  ProviderFleetList,
  ProviderCouriersList,
  ProviderPeonetasList,
  ProviderEmployeeEdit,
} from "../v2/pages/ProviderList";
import {
  CourierList,
  PeonetaList,
  ProviderList,
  EmployeeEdit,
} from "../v2/pages/Employee";
import { FleetList } from "../v2/pages/Fleet";
import ReplaceFleet from "../v2/pages/Fleet/ReplaceFleet";
import ReplaceEmployee from "../v2/pages/Employee/ReplaceEmployee";
import { RequestsBackoffice } from "../v2/pages/Requests";
import { Replace } from "../v2/pages/Requests/Replace";
import { ReplaceDocument } from "../v2/pages/Requests/ReplaceDocument";
import { RequestsProvider } from "../v2/pages/Requests";
import SecurityRequests from "../v2/pages/Security/requests/SecurityRequests";
import SecurityRequestMassive from "../v2/pages/Security/requestMassive";
import { services } from "../constants/access";

export function Routes(params) {
  const { nav } = params;
  const [startNavigate, setStartNavigate] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    authorization,
    email: providerEmail,
    validatePermissions,
  } = useAuth();

  const service = authorization?.find((e) => e.service === services.CURRENT);
  const ownProviderRequest = providerEmail
    ? useMyProvider(providerEmail)
    : null;

  useEffect(() => {
    if (startNavigate || ownProviderRequest?.isLoading) {
      return;
    }
    if (!validatePermissions(ALL_PERMISSIONS)) {
      navigate("/404");
      setStartNavigate(true);
      return;
    }
    const isProviderRole = service?.roles?.includes(ROLES.PROVIDER_TMS);
    const hasProviderData = Boolean(ownProviderRequest?.data);

    if (isProviderRole && !hasProviderData) {
      const completeProfile = "profile/edit";
      const redirection = nav.includes(completeProfile)
        ? nav.replace(completeProfile, "")
        : `?navigate=${nav || "/"}`;
      navigate(`${completeProfile}${redirection}`, {
        state: {
          fromButton: true,
          nav: redirection.replace("?navigate=", ""),
        },
      });
      setStartNavigate(true);
      return;
    }

    navigate(`/${nav}`, {
      state: {
        fromButton: true,
        nav,
      },
    });
    setStartNavigate(true);
  }, [nav, ownProviderRequest]);

  if (!startNavigate) {
    return;
  }

  return (
    <Router>
      <Route index element={<div />} />
      <Route path="offers" element={<Outlet />}>
        <Route
          path="list"
          element={
            <ProtectedRoute
              functionality={[OFFER_LIST]} //si
              outlet={<ListOffers />}
            />
          }
        />
        <Route
          path=":offerId"
          element={
            <ProtectedRoute
              functionality={[OFFER_LIST]} //si
              outlet={<OfferDetails fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path="create"
          element={
            <ProtectedRoute
              functionality={[OFFER_LIST]} //si
              outlet={<FormOffer fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path="update/:offerId"
          element={
            <ProtectedRoute
              functionality={[OFFER_LIST]} //si
              outlet={<FormOffer fromButton={state?.fromButton} />}
            />
          }
        />
      </Route>
      <Route
        path="/listApplications"
        element={
          <ProtectedRoute functionality={[APPLY_LIST]} outlet={<ListApply />} />
        }
      />
      <Route path="requests" element={<Outlet />}>
        <Route
          path="list"
          element={
            <ProtectedRoute
              functionality={[REQUEST_LIST]}
              outlet={<RequestsBackoffice />}
            />
          }
        />
        <Route
          path="replacement/:requestId"
          element={
            <ProtectedRoute
              functionality={[REQUEST_LIST]}
              outlet={<Replace />}
            />
          }
        />
        <Route
          path="replacementdocument/:requestId"
          element={
            <ProtectedRoute
              functionality={[REQUEST_LIST]}
              outlet={<ReplaceDocument />}
            />
          }
        />
        <Route
          path="provider/list"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_REQUESTS_LIST]}
              outlet={<RequestsProvider />}
            />
          }
        />
      </Route>

      <Route
        path="/featuresVehicles"
        element={
          <ProtectedRoute
            functionality={[CONFIG_FLEET]}
            outlet={<FeaturesVehicles />}
          />
        }
      />

      <Route
        path="/featuresVehicles/create"
        element={
          <ProtectedRoute
            functionality={[CONFIG_FLEET]}
            outlet={<FormFeaturesVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/featuresVehicles/update/:featureId"
        element={
          <ProtectedRoute
            functionality={[CONFIG_FLEET]}
            outlet={<FormFeaturesVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/typeVehicles"
        element={
          <ProtectedRoute
            functionality={[CONFIG_FLEET]}
            outlet={<TypeVehicles />}
          />
        }
      />

      <Route
        path="/typeVehicles/create"
        element={
          <ProtectedRoute
            functionality={[CONFIG_FLEET]}
            outlet={<FormTypeVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/typeVehicles/update/:typeId"
        element={
          <ProtectedRoute
            functionality={[CONFIG_FLEET]}
            outlet={<FormTypeVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/bases"
        element={
          <ProtectedRoute functionality={[CONFIG_BASES]} outlet={<Bases />} />
        }
      />

      <Route
        path="/Bases/create"
        element={
          <ProtectedRoute
            functionality={[CONFIG_BASES]}
            outlet={<FormBases fromButton={state?.fromButton} />}
            path="/Documents"
            element={
              <ProtectedRoute
                functionality={[CONFIG_BASES]}
                outlet={<Documents />}
              />
            }
          />
        }
      />

      <Route
        path="/Bases/update/:baseId"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<FormBases fromButton={state?.fromButton} />}
            path="/Documents/create"
            element={
              <ProtectedRoute
                functionality={[CONFIG_DOCUMENTS]}
                outlet={<FormDocuments fromButton={state?.fromButton} />}
              />
            }
          />
        }
      />

      <Route
        path="/Documents/update/:documentId"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<FormDocuments fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/Types"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<Types />}
          />
        }
      />

      <Route
        path="/Types/create"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<FormDocumentTypes />}
          />
        }
      />

      <Route
        path="/Types/update/:documentTypeId"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<FormDocumentTypes />}
          />
        }
      />

      <Route
        path="/Templates"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<Templates />}
          />
        }
      />

      <Route
        path="/Templates/create"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<FormTemplates fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/Templates/update/:templateId"
        element={
          <ProtectedRoute
            functionality={[CONFIG_DOCUMENTS]}
            outlet={<FormTemplates fromButton={state?.fromButton} />}
          />
        }
      />
      <Route
        path="provider/list"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_REQUESTS_LIST]}
            outlet={<RequestsProvider />}
          />
        }
      />

      <Route
        path="courier"
        element={
          <ProtectedRoute
            functionality={[COURIER_LIST]}
            outlet={<CourierList />}
          />
        }
      />

      <Route
        path="peoneta"
        element={
          <ProtectedRoute
            functionality={[PEONETA_LIST]}
            outlet={<PeonetaList />}
          />
        }
      />

      <Route
        path="provider"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_LIST]}
            outlet={<ProviderList />}
          />
        }
      />

      <Route
        path="fleet"
        element={
          <ProtectedRoute functionality={[FLEET_LIST]} outlet={<FleetList />} />
        }
      />
      {/** SEGURIDAD */}

      <Route
        path="/securityApplications"
        element={
          <ProtectedRoute
            functionality={[SECURITY_APPLY_LIST]}
            outlet={<Security />}
          />
        }
      />

      <Route
        path="/securityApplicationsMassive"
        element={
          <ProtectedRoute
            functionality={[SECURITY_APPLY_LIST]}
            outlet={<SecurityApplicationsMassive />}
          />
        }
      />

      <Route
        path="/securityRequests"
        element={
          <ProtectedRoute
            functionality={[SECURITY_REQUEST_LIST]}
            outlet={<SecurityRequests />}
          />
        }
      />

      <Route
        path="/securityRequestsMassive"
        element={
          <ProtectedRoute
            functionality={[SECURITY_REQUEST_LIST]}
            outlet={<SecurityRequestMassive />}
          />
        }
      />

      {/** PROVEEDORES */}

      <Route
        path="/myrequest"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_APPLY_LIST]}
            outlet={<ProvidersRequests />}
          />
        }
      />
      <Route
        path="/offerApplication"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_APPLY_LIST]}
            outlet={<OfferApply></OfferApply>}
          />
        }
      />
      <Route path="employee" element={<Outlet />}>
        <Route path="list/:step" element={<ListEmployee />} />
        <Route
          path="update/:employeeId"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<ManageEmployee fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path="replace/:applyId/:employeeId"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<ReplaceEmployee />}
            />
          }
        />
      </Route>
      <Route path="vehicle" element={<Outlet />}>
        <Route
          path="list/:step"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<NewFleetStep />}
            />
          }
        />
        <Route
          path="replace/:applyId/:fleetId"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<ReplaceFleet />}
            />
          }
        />
      </Route>
      <Route path="finish" element={<Outlet />}>
        <Route
          path=":step"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<FinishApply />}
            />
          }
        />
      </Route>
      <Route path="application" element={<ApplyOutlet />}>
        <Route
          path=":applyId/employee/:step"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<ListEmployee />}
            />
          }
        />
        <Route
          path=":applyId/fleet/:step"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_APPLY_LIST]}
              outlet={<NewFleetStep />}
            />
          }
        />
      </Route>
      <Route
        path="applyNotAllowed"
        element={
          <RejectedApply message={state?.message ?? "Ups hubo un problema"} />
        }
      />
      <Route path="profile" element={<Outlet />}>
        <Route
          path="my-data"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_PROFILE]}
              outlet={<Profile />}
            />
          }
        />
        <Route
          path="edit"
          element={
            <ProtectedRoute
              functionality={[PROVIDER_PROFILE]}
              outlet={<CreateProfile />}
            />
          }
        />
      </Route>
      <Route
        path="myfleetlist"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_FLEET_LIST]}
            outlet={<ProviderFleetList />}
          />
        }
      />

      <Route
        path="myCouriers"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_COURIER_LIST]}
            outlet={<ProviderCouriersList />}
          />
        }
      />

      <Route
        path="myPeonetas"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_PEONETA_LIST]}
            outlet={<ProviderPeonetasList />}
          />
        }
      />

      <Route
        path="provider/employee/edit/:employeeId"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_COURIER_LIST]}
            outlet={<ProviderEmployeeEdit />}
          />
        }
      />

      <Route
        path="employee/edit/:employeeId"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_COURIER_LIST, COURIER_EDIT, PEONETA_EDIT]}
            outlet={<EmployeeEdit />}
          />
        }
      />

      <Route path="/404" element={<Error404 />} />
    </Router>
  );
}
