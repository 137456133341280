/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useState } from "react";
import { Box, Container, Drawer, Stack, Toolbar } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";

import logoImg from "../../assets/images/bx-copec-v2-min.svg";

const ContentDrawer = () => (
  <>
    <Stack alignItems="center" justifyContent="center" paddingY={2}>
      <img src={logoImg} alt="logo" height={40} width={81} />
    </Stack>
    <Sidebar />
  </>
);

export function Layout(props) {
  const { children, showSideBar } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <BrowserRouter>
      <Box
        sx={{
          height: "100vh",
          display: "grid",
          gridTemplateAreas: {
            xs: `
            "header"
            "content" 
            "footer"
          `,
            md: `
            "sidebar header header"
            "sidebar content content" 
            "sidebar footer footer"
          `,
          },
          gridTemplateColumns: {
            xs: "auto",
            md: `${!showSideBar ? "0" : 240}px auto auto`,
          },
          gridTemplateRows: "auto 1fr auto",
        }}
      >
        <Header handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="aside"
          sx={{
            display: !Boolean(showSideBar)
              ? "none"
              : { xs: "none", md: "block" },
            gridArea: "sidebar",
          }}
        >
          <ContentDrawer />
        </Box>
        <Box
          component="main"
          sx={{
            gridArea: "content",
            backgroundColor: "lightDay",
            py: 4,
            overflowX: "auto",
          }}
        >
          <Container maxWidth="xl">{children}</Container>
        </Box>
        <Footer />
      </Box>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <ContentDrawer />
      </Drawer>
    </BrowserRouter>
  );
}
