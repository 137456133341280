/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { alias, services } from "../constants/access";
import { useSession } from "@blue-express/bx-lib-universal-frontend";

export const checkPermission = (functionalities, functionality) =>
  functionalities?.some((func) => functionality.includes(func));

export const fetchFunctionalitiesByService = (authorizations, service) => {
  const functionalities = [];
  const foundService = authorizations?.find(
    (serviceWrap) => serviceWrap.service === service
  );
  functionalities.push(...(foundService.functionalities ?? []));
  return functionalities;
};

export const fetchFunctionalitiesWithoutService = (
  authorizations,
  service,
  alias
) => {
  const functionalities = [];
  authorizations?.forEach((serviceWrap) => {
    if (serviceWrap.service === service) {
      serviceWrap?.functionalities?.map((e, i) => {
        serviceWrap.functionalities[i] = e.split(".", 1)[0] === alias ? e : "";
      });
      functionalities.push(...(serviceWrap?.functionalities ?? []));
    }
  });

  return functionalities;
};

export const fetchSessionFunctionalities = (session) => {
  if (session?.authorization?.length > 1) {
    return fetchFunctionalitiesWithoutService(
      session.authorization,
      services.CURRENT,
      alias.CURRENT
    );
  }
  return fetchFunctionalitiesByService(session.authorization, services.CURRENT);
};

export const hasFuncionality = (functionality) => {
  const { getSession } = useSession();
  const session = getSession();
  const functionalities = fetchSessionFunctionalities(session);
  return checkPermission(functionalities, functionality);
};
