import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../services/api";
import { EMPLOYEE_URI } from "../../../../constants/api";

const { axiosInstance } = useApi();

const deleteHardEmployee = async ({ idToDelete }) => {
  return await axiosInstance.delete(`${EMPLOYEE_URI}/hard/${idToDelete}`);
};

export function useDeleteHardEmployee() {
  const queryClient = useQueryClient();
  return useMutation(deleteHardEmployee, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries("listEmployees");
      }, 500);
    },
  });
}
