import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Stack,
  Switch,
  TableBody,
  TableCell,
  TableRow,
  Grid,
} from "@mui/material";
import { Table } from "../../components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { ActiveStatus, EmployeeStatus } from "../../../constants/status";
import ListDocumentsModal from "../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../constants/vacancyType";
import { headCellsFleets } from "../../helper/headcell";
import { useListFleets } from "../../services/hooks/Fleets/useListFleets";
import { TabAddonsList } from "../../components/TabsAddon";
import { formatDateApprovalFlow } from "../../../utils/formatValues";
import { useURLfilters } from "../../hooks/filters.hook";
import { useReportFleets } from "../../services/hooks/Fleets/useReportFleets";
import { urlDownload } from "../../helper/files";
import { AlertSnackBar } from "../../../components/Alerts";
import { ReactComponent as InfoButton } from "../../assets/images/infobutton.svg";
import { ReactComponent as DeleteButton } from "../../assets/images/trash.svg";
import { TableButton } from "../../components/Button/TableButton";
import { Dialogs } from "../../../components/Dialogs";
import { useDeleteHardFleet } from "../../services/hooks/Fleets/useHardDeleteFleet";
import wowIcon from "../../../assets/images/wow.png";
import { useUpdateActiveStatus } from "../../services/hooks/Fleets/useUpdateActiveStatus";
import { Alert } from "../../../components/Alert";
import { maskRUT } from "../../../utils";
import { useListBasesCodes } from "../../services/hooks/Bases/useListBasesCodes";
import { SelectorInput } from "../Requests/components/SelectorInput";
import { objectToTypeOptionList } from "../../helper/converters";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { hasFuncionality } from "../../../utils/permissions";

import {
  FLEET_LIST,
  FLEET_DELETE,
  FLEET_ACTIVATE,
  FLEET_INFO,
} from "../../../constants/functionalities";

const defaultFilters = {
  page: 1,
  limit: 10,
  status: `${EmployeeStatus.BLOCKEDBYDOCUMENTS},${EmployeeStatus.APPROVED}`,
};

export default function FleetList() {
  const [item, setItem] = useState();
  const [idToDelete, setIdToDelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [error, setError] = useState({ isOpen: false });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogActiveStatus, setOpenDialogActiveStatus] = useState(false);
  const [fleetSelected, setFleetSelected] = useState();
  const { data: listGeographiesCodes, isLoading: basesLoading } =
    useListBasesCodes();
  const {
    filters,
    setFilters,
    getURLValue,
    cleanFilters,
    getArrayFromURLValue,
  } = useURLfilters(defaultFilters);
  const [baseCode, setBaseCode] = useState(getURLValue("base", undefined));

  const onBaseChange = (event) => {
    const baseValue = event.target.value;
    setBaseCode(baseValue);
    const base = baseValue && baseValue.length === 0 ? undefined : baseValue;
    setFilters({ base, page: 1 });
  };

  const [alert, setAlert] = useState({
    title: "",
    open: false,
    messages: [],
    icon: "",
    buttonClose: true,
  });

  const BaseCodeSelector = useCallback(
    ({ onChange, value }) => {
      if (!listGeographiesCodes) return null;
      const bases = getArrayFromURLValue("base", undefined);
      return (
        <SelectorInput
          label="Seleccionar Base"
          value={value ?? bases}
          onChange={onChange}
          options={objectToTypeOptionList(listGeographiesCodes)}
        />
      );
    },
    [listGeographiesCodes, baseCode]
  );

  const { mutate: mutateReportFleets, isLoading: isLoadingReport } =
    useReportFleets();

  const { mutate: mutateDeleteFleet, isLoading: isLoadingDeleteFleet } =
    useDeleteHardFleet();

  const {
    mutate: mutateUpdateActiveStatus,
    isLoading: isLoadingUpdateActiveStatus,
  } = useUpdateActiveStatus();

  const { data, isLoading } = useListFleets(filters);

  const onChangeFilters = (newFilter = {}) => {
    setFilters({ ...filters, ...newFilter });
  };

  const { TableContainer, setTableLoading, setPage } = Table(
    headCellsFleets,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );
  const cleanAllFilters = () => {
    cleanFilters();
    setBaseCode(undefined);
  };

  useEffect(() => {
    setTableLoading(isLoading);
    if (data) {
      setDownloadData(
        data.data.map((item) => {
          return {
            plate: item.plate,
            type: item.type,
            brand: item.brand,
            model: item.model,
            year: item.year,
            entryDate: item.entryDate ?? "-",
            active: item?.active?.isActive
              ? ActiveStatus.ACTIVE
              : ActiveStatus.INACTIVE,
            blocked: item.blocked.isBlocked ? "SI" : "NO",
            dateDeleted: item.isDeleted ? item.deleted.at : "-",
          };
        })
      );
    }
  }, [data, isLoading]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    onChangeFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeBlocked = (event) => {
    onChangeFilters({ isBlocked: event.target.value, page: 1 });
  };

  const onChangeActive = (event) => {
    onChangeFilters({ isActive: event.target.value, page: 1 });
  };

  const handleDownloadReport = () => {
    mutateReportFleets(filters, {
      onSuccess: (filePath) => {
        urlDownload(filePath);
      },
      onError: () => {
        setError({
          isOpen: true,
          title: "Error",
          message:
            "Hubo un error al descargar el reporte de flotas. Inténtelo nuevamente.",
          handleClose: () => setError({ isOpen: false }),
        });
      },
    });
  };

  const handleDelete = () => {
    mutateDeleteFleet(
      { idToDelete },
      {
        onSuccess: () => {
          setOpenDialogDelete(false);
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: ["El vehículo ha sido eliminado con éxito"],
          });
        },
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message:
              "Hubo un error al eliminar el vehículo. Inténtelo nuevamente.",
            handleClose: () => setError({ isOpen: false }),
          });
        },
      }
    );
  };

  const handleCloseDialogActive = () => {
    setOpenDialogActiveStatus(false);
  };

  const handleActiveStatus = () => {
    const action = fleetSelected?.active?.isActive ? "inactive" : "active";
    const id = fleetSelected?.id;
    mutateUpdateActiveStatus(
      { id, action },
      {
        onSuccess: () => {
          setOpenDialogActiveStatus(false);
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: ["El vehículo ha sido actualizado con éxito"],
          });
        },
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message:
              "Hubo un error al actualizar el vehículo. Inténtelo nuevamente.",
            handleClose: () => setError({ isOpen: false }),
          });
        },
      }
    );
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const TabsAddons = () => (
    <TabAddonsList
      search={getURLValue("search", "")}
      active={getURLValue("isActive", "")}
      blocked={getURLValue("isBlocked", false)}
      downloadData={downloadData}
      handleSearch={handleSearch}
      onChangeActive={onChangeActive}
      onChangeBlocked={onChangeBlocked}
      headCell={headCellsFleets}
      nameFile={vacancyType.FLEET}
      clearFilters={cleanAllFilters}
      handleDownloadReport={handleDownloadReport}
    />
  );

  if (isLoading || isLoadingReport || basesLoading)
    return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <Grid>
        <ProtectedComponent functionality={[FLEET_LIST]}>
          <BaseCodeSelector onChange={onBaseChange} value={baseCode} />
        </ProtectedComponent>
      </Grid>
      <TableContainer addons={[TabsAddons]} withPagination>
        <TableBody>
          {data?.data.map((fleet) => (
            <TableRow hover tabIndex={-1} key={fleet.id}>
              <TableCell>{fleet.base?.code ?? "-"}</TableCell>
              <TableCell>
                {fleet.entryDate
                  ? formatDateApprovalFlow(fleet.entryDate)
                  : "-"}
              </TableCell>
              <TableCell>{fleet.plate}</TableCell>
              <TableCell sx={{ opacity: 1 }}>{fleet.brand}</TableCell>
              <TableCell sx={{ opacity: 1 }}>{fleet.model}</TableCell>
              <TableCell>{fleet.year}</TableCell>
              <TableCell>{fleet.provider?.name ?? "-"}</TableCell>
              <TableCell>
                {fleet.provider?.numberRut
                  ? maskRUT(fleet.provider?.numberRut)
                  : "-"}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                <Switch
                  color="warning"
                  onClick={() => {
                    setOpenDialogActiveStatus(true);
                    setFleetSelected(fleet);
                  }}
                  defaultChecked={fleet?.active?.isActive}
                  disabled={!hasFuncionality(FLEET_ACTIVATE)}
                />
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {fleet.blocked.isBlocked ? "SI" : "NO"}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <ProtectedComponent functionality={[FLEET_INFO]}>
                    <TableButton
                      onClick={() => {
                        setItem(fleet);
                        setShowModal(true);
                      }}
                    >
                      <InfoButton />
                    </TableButton>
                  </ProtectedComponent>
                  <ProtectedComponent functionality={[FLEET_DELETE]}>
                    <TableButton
                      onClick={() => {
                        setIdToDelete(fleet?.id);
                        setOpenDialogDelete(true);
                      }}
                    >
                      <DeleteButton />
                    </TableButton>
                  </ProtectedComponent>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.FLEET}
        item={item}
      />
      <Dialogs
        open={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirmation={handleDelete}
        isLoading={isLoadingDeleteFleet}
        title="¿Estás seguro de que quieres eliminar el vehículo?"
        content={"Esta acción no se puede deshacer"}
      />
      <Dialogs
        open={openDialogActiveStatus}
        handleClose={handleCloseDialogActive}
        handleConfirmation={handleActiveStatus}
        isLoading={isLoadingUpdateActiveStatus}
        title={`¿Estás seguro de que quieres ${
          fleetSelected?.active?.isActive ? "inactivar" : "activar"
        } este vehículo?`}
        content={"Esta acción no se puede deshacer"}
      />
      <Alert
        open={alert.open}
        title={alert.title}
        messages={alert.messages}
        icon={alert.icon}
        buttonClose={alert.buttonClose}
        onClose={handleCloseAlert}
        handleConfirmation={handleCloseAlert}
      />
    </Stack>
  );
}
