import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import applySuccess from "../../../../assets/images/apply-success.png";
import { PaperWhite } from "../../../../components/Papers";

export const FinishReplace = ({ entity, goBackTitleButton, goBack}) => {
    const navigate = useNavigate();

    return (
      <>
        <PaperWhite >
          <Box
            alignSelf='center'
            component='img'
            alt="Postulación finalizada"
            src={applySuccess}
            sx={{
                display: "block"
            }}
            width={"134.41px"}
            height={"133.75px"}
            mx="auto"
            my={2}
          />
          <Typography fontFamily={"Montserrat"} fontWeight={"800"} fontSize={"24px"} lineHeight={"29.26px"} color="#212121" align="center">
            {"¡Buen trabajo!"}
          </Typography>
          <Typography fontFamily={"Lato"} fontWeight={"800"} fontSize={"16px"} lineHeight={"21.6px"} color="#212121" align="center">
            {`Tu solicitud de reemplazo se ha generado con exito. Se evaluará el reemplazo lo antes posible.`}
            <br/>
            {`Puedes revisar el estado de tu solicitud en "Mis solicitudes"`}
          </Typography>
          <Stack
            direction='row'
            justifyContent='center'
            paddingY={2}
            spacing={10}
          >
            <Button 
              variant='outlined' 
              onClick={() => navigate(`/requests/provider/list?tab=${entity}`)}
              sx={{
                border:"1.5px solid #FF7E44", 
                borderRadius:"12px", 
                width:"200px", 
                height:"40px",
                padding:"10px, 16px, 10px, 16px"
              }}>
                {"Ir a Mis Solicitudes"}
            </Button>

            <Button
                onClick={() => goBack()}
                sx={{
                  borderRadius:"12px", 
                  width:"200px", 
                  height:"40px",
                  padding:"0px"
                }}
            >
                {goBackTitleButton}
            </Button>
        </Stack>
        </PaperWhite>
      </>
    )
}