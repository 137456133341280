import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../services/api";
import { EMPLOYEE_URI } from "../../../../constants/api";

const { axiosInstance } = useApi();

const updateActiveStatus = async ({ id, action }) => {
  return await axiosInstance.put(`${EMPLOYEE_URI}/activation/${id}/${action}`);
};

export function useUpdateActiveStatus() {
  const queryClient = useQueryClient();
  return useMutation(updateActiveStatus, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries("listEmployees");
      }, 500);
    },
  });
}
