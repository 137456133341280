// REACT
import { Navigate } from "react-router-dom";

// UNIVERSAL
import { useSession } from "@blue-express/bx-lib-universal-frontend";

import {
  fetchSessionFunctionalities,
  checkPermission,
} from "../../utils/permissions";

export const ProtectedRoute = ({ functionality, outlet }) => {
  const { getSession } = useSession();
  const session = getSession();

  const functionalities = fetchSessionFunctionalities(session);

  if (!checkPermission(functionalities, functionality)) {
    return <Navigate to="/404" />;
  }

  return outlet;
};
