import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../../services/api';
import { FLEET_URI } from '../../../../constants/api';

const { axiosInstance } = useApi();

const deleteHardFleet = async ({ idToDelete}) => {
  return await axiosInstance.delete(`${FLEET_URI}/fleet/hard/${idToDelete}`);
}

export function useDeleteHardFleet() {
  const queryClient = useQueryClient();
  return useMutation(deleteHardFleet, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries('getFleetsByProviderAndOffer');
        queryClient.refetchQueries('listFleets');
      }, 500);
    },
  });
}