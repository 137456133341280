import { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ReactComponent as UploadFileIcon } from "../../assets/images/uploadFile.svg"

const baseStyle = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 16,
  borderColor: '#0032A0',
  borderStyle: 'dashed',
  backgroundColor: '#FBFBFB',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width:"270px",
  height:"140px",
}

const acceptStyle = {
  borderColor: '#00e676',
  backgroundColor: '#00e67721',
}

const rejectStyle = {
  borderColor: '#ff1744',
  backgroundColor: '#ff174524',
}

const acceptFile = {
  'employee': '.pdf, .jpg, .jpeg, .gif o .png',
  'provider': '.pdf, .jpg, .jpeg, .gif o .png',
  'fleet': '.pdf, .jpg, .jpeg, .gif o .png',
  'summary': '.pdf, .jpg, .jpeg, .gif o .png',
  'application/pdf': '.pdf',
  'image/png': '.png',
  'image/jpeg': '.jpg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'text/csv': '.csv',
  'text/xlsx': '.xlsx',
}

export function UploadFile({
  typeFile,
  acceptedFiles,
  getRootProps,
  getInputProps,
  isDragAccept,
  isDragReject,
}) {
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  )

  const extensionFile = useMemo(() => acceptFile[typeFile], [typeFile])

  return (
    <Stack spacing={4} sx={{ p: 4 }}>
      <Box>
        <Typography 
          variant='body1' 
          align='center' 
          fontFamily={'Lato'} 
          fontWeight={800} 
          fontSize={'16px'} 
          lineHeight={'21.6px'}
        >
          Sube tu archivo
        </Typography>
        <Typography 
          variant='body1' 
          align='center' 
          fontFamily={'Lato'} 
          fontWeight={400} 
          fontSize={'14px'} 
          lineHeight={'18.9x'}
        >
          Carga aqui el archivo ({extensionFile})
        </Typography>
      </Box>
      <Box sx={{display: 'flex', justifyContent:'center'}}>
        <Stack spacing={2} alignItems='center' {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <UploadFileIcon sx={{ color: '#0032A0', width: '46px', height: '46px'}}/>
          <Typography 
            variant='body1' 
            align='center' 
            fontFamily={'Lato'} 
            fontWeight={400} 
            fontSize={'12px'} 
            lineHeight={'16px'}
            sx={{ color: '#0032A0', textAlign: 'center' }}
          >
            {acceptedFiles.length > 0 ?  acceptedFiles[0]?.name : 'Arrastra tu archivo o selecciona desde tu computadora'}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}
