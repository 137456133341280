export const ENTITY_TYPES = {
  PROVIDER: "provider",
  FLEET: "fleet",
  EMPLOYEE: "employee",
  REQUESTS: "requests",
};
export const PROVIDER_ASSETS_TYPE = {
  FLEET: "fleet",
  COURIER: "courier",
  PEONETA: "peoneta",
  PROVIDER: "provider",
};
export const ENTITY_TYPES_MAPPING = {
  fleet: "Vehículo",
  courier: "Conductor",
  peoneta: "Peoneta",
  provider: "Proveedor",
};
