import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { LOCALITY_URI } from "../../../../constants/api";

export async function getListGeographiesCodes() {
  const { axiosInstance } = useApi();

  const response = await axiosInstance.get("/?page=1&limit=1000", {
    baseURL: LOCALITY_URI,
  });

  const { data } = response.data;
  const codes = data
    .flatMap((item) => item.bases)
    .reduce((acc, base) => {
      acc[base.code] = base.code;
      return acc;
    }, {});
  return codes;
}

export function useListBasesCodes() {
  return useQuery("listGeographiesCodes", () => getListGeographiesCodes(), {
    staleTime: 1000 * 60 * 10,
  });
}
