import { useMutation, useQueryClient } from "react-query";
import { REQUEST_TRACKER_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";

export const approvationRequestMassive = async (data) => {
  const { axiosInstance } = useApi();

  await axiosInstance.put('/request/massive/security', data, {
      baseURL: REQUEST_TRACKER_URI,
    }
  );

  return;
};

export function useApprovationRequestMassive() {
  const queryClient = useQueryClient();
  return useMutation(approvationRequestMassive, {
    onSuccess: () => {
      queryClient.refetchQueries("reportRequests");
    },
    onError: () => {
      queryClient.refetchQueries("reportRequests");
    },
  });
}