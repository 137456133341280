export const services = {
  UNIVERSAL: "universal",
  TMS: "tms",
  CURRENT: "tms",
};

export const alias = {
  UNIVERSAL: "uapp",
  TMS: "tms",
  CURRENT: "flota",
};
