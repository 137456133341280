import { useQuery } from 'react-query'
import { EMPLOYEE_URI } from '../../../../constants/api';
import { useApi } from '../../../../services/api';
import { buildUrlWithQueryParams } from '../../../helper/converters';

export async function getListEmployees( filters) {
  if (Object.keys(filters || {}).length === 0) return;
  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(`${EMPLOYEE_URI}/filter`, filters); 

  const response = await axiosInstance.get(url);

  const { data } = response;

  return data;
}

export function useListEmployee(filters) {
  return useQuery(["listEmployees", filters], () => getListEmployees(filters));
}