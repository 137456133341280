import React, { useState } from "react";
import {
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { PaperWhite } from "../../../components/Papers";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import DownloadExcel from "./components/downloadExcel";
import { useReportRequest } from "../../services/hooks/Security/useReportRequest";
import { EmployeeStatus } from "../../../constants/status";
import { UploadFinish } from "./components/uploadFinish";
import UploadMassiveExcel from "./components/uploadMassiveExcel";
import { useApprovationRequestMassive } from "../../services/hooks/Security/useApprovationRequestMassive";

export default function SecurityRequestMassive() {
  const [uploadFinish, setUploadFinish] = useState(false);
  const { data: employeeRequests, isLoading: isLoadingEmployeeRequests } = useReportRequest({status: EmployeeStatus.BACKOFFICEAPPROVED});
  const { mutate: mutateApprovationMassive } = useApprovationRequestMassive();

  if (uploadFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Solicitudes`}
        </Typography>
        <UploadFinish
          goBack={() => setUploadFinish(false)}
          goBackTitleButton={`Volver a Carga masiva`}
        />
      </Stack>
    );
  }

  if (isLoadingEmployeeRequests) return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Solicitudes
      </Typography>
      <PaperWhite direction="row" spacing={2}>
        <DownloadExcel
          title={'Aprobación de solicitudes de Conductores y Peonetas'}
          content={`Tienes ${employeeRequests?.metadata?.total_courier} solicitudes de Conductores y
          ${employeeRequests?.metadata?.total_peoneta} de Peonetas pendientes de aprobación.`}
          dataAllEmployee={employeeRequests}
        />
        <Divider sx={{ pt: 1, pb: 2, mb: 3 }} />
        <UploadMassiveExcel 
          mutateApprovationMassive={mutateApprovationMassive} 
          setUploadFinish={setUploadFinish} 
          dataAllEmployee={employeeRequests}
        />
      </PaperWhite>
    </Stack>
  );
}
