import React, { useEffect, useState } from "react";

import { useQueryClient } from "react-query";

import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
} from "@mui/material";
import {
  ThumbUpOffAltOutlined,
  ThumbDownOffAltOutlined,
} from "@mui/icons-material";

import styles from "./TabsAddon.module.css";
import DownloadIcon from "@mui/icons-material/Download";

import { MessageBox } from "../../../components/common/MessageBox";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Dialogs } from "../../../components/Dialogs";

import { dynamicHeadCells, getNestedValues } from "./headCells";
import { EmployeeStatus } from "../../../../src/constants/status";

import { useListSecurity } from "../../services/hooks/Security/useListSecurity";
import { useApproveEmployee } from "../../services/hooks/Offer/useApproveEmployee";
import { ChipStatus } from "../../components/Chip";
import { urlDownload } from "../../helper/files";
import { AlertSnackBar } from "../../../components/Alerts";
import { useReportProvider } from "../../services/hooks/Employee/useReportProviders";
import { useReportEmployeeApplies } from "../../services/hooks/apply/useReportEmployeeApplies";
import { ENTITY_TYPES } from "../../../constants/entityTypes";

export default function Security() {
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState("courier");
  const [currentHeadCells, setCurrentHeadCells] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [filters, setFilters] = useState({ employeeType: activeTab });
  const { data, isLoading, error } = useListSecurity(filters);

  const [errorReport, setErrorReport] = useState({ isOpen: false });
  const { mutate: mutateProvidersReport, isLoading: isLoadingProvidersReport } =
    useReportProvider();
  const { mutate: mutateEmployeesReport, isLoading: isLoadingEmployeesReport } =
    useReportEmployeeApplies();

  const [openDialogApproveEmployee, setOpenDialogApproveEmployee] =
    useState(false);
  const [manageEmployeeId, setManageEmployeeId] = useState("");
  const [manageEmployeeAction, setManageEmployeeAction] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [applyId, setApplyId] = useState("");
  const {
    mutate: manageEmployeeMutate,
    isLoading: manageEmployeeIsLoading,
    error: employeeError,
  } = useApproveEmployee();

  const { TableContainer } = Table(
    currentHeadCells,
    data?.metadata,
    onChangeFilters
  );

  const handleTabChange = (tab) => {
    const pageAndLimit = {
      page: 1,
      limit: 100,
    };
    setFilters(() => ({
      [tab === "provider" ? "type" : "employeeType"]: tab,
      ...(tab === "provider"
        ? { status: "backofficeApproved", profile: "security" }
        : {}),
      ...pageAndLimit,
    }));

    setActiveTab(tab);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setDebouncedSearch(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        const searchInput = value.toLowerCase();
        if (searchInput !== undefined) {
          setFilters((old) => ({ ...old, search: searchInput }));
        }
      }, 2000)
    );
  };

  const handleCloseDialogDeleteEmployee = () => {
    setOpenDialogApproveEmployee(false);
  };

  const handleApproveEmployee = () => {
    if (manageEmployeeId) {
      manageEmployeeMutate(
        { manageEmployeeId, manageEmployeeAction, activeTab, applyId },
        {
          onSuccess: () => {
            setManageEmployeeId("");
            setOpenDialogApproveEmployee(false);
            setDialogTitle("");
          },
        }
      );
    }
  };

  const handleDownloadReport = () => {
    if (filters.type === ENTITY_TYPES.PROVIDER) {
      mutateProvidersReport(filters, {
        onSuccess: (filePath) => {
          urlDownload(filePath);
        },
        onError: () => {
          setErrorReport({
            isOpen: true,
            title: "Error",
            message:
              "Hubo un error al descargar el reporte de proveedores. Inténtelo nuevamente.",
            handleClose: () => setErrorReport({ isOpen: false }),
          });
        },
      });
    } else {
      mutateEmployeesReport(filters, {
        onSuccess: (filePath) => {
          urlDownload(filePath);
        },
        onError: () => {
          setErrorReport({
            isOpen: true,
            title: "Error",
            message:
              "Hubo un error al descargar el reporte de empleados. Inténtelo nuevamente.",
            handleClose: () => setErrorReport({ isOpen: false }),
          });
        },
      });
    }
  };

  function onChangeFilters(newFilters = {}) {
    setFilters((old) => ({
      ...old,
      ...newFilters,
    }));
  }

  useEffect(() => {
    setCurrentHeadCells(dynamicHeadCells[activeTab]);
    if (debouncedSearch !== "") queryClient.invalidateQueries("listSecurity");
  }, [activeTab, debouncedSearch]);

  if (error || employeeError)
    return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || isLoadingProvidersReport || isLoadingEmployeesReport)
    return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="flex-start"
      className={styles.tabAddon}
    >
      <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
        <Tabs
          value={activeTab}
          onChange={(e, searchInput) => handleTabChange(searchInput)}
        >
          <Tab label="Conductor" value="courier" />
          <Tab label="Proveedor" value="provider" />
          <Tab label="Peoneta" value="peoneta" />
        </Tabs>
      </Stack>
      <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="Buscar"
          onSearch={handleSearch}
        />
      </Stack>
      <Stack className={styles.downLoadBtn}>
        <Box sx={{ paddingLeft: "1rem" }}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon color="inherit" />}
            onClick={handleDownloadReport}
            size="medium"
          >
            Descargar
          </Button>
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Stack spacing={4}>
        <TableContainer addons={[TabsAddon]} withPagination>
          <TableBody>
            {data?.data &&
              data?.data.map((record) => {
                return (
                  <TableRow key={record.id}>
                    {currentHeadCells?.map((headCell) => {
                      return (
                        <>
                          {headCell.id === "actions" ? (
                            <TableCell>
                              <Stack direction="row" spacing={1.5}>
                                <IconButton
                                  onClick={() => {
                                    setManageEmployeeId(
                                      record.id ? record.id : record.vacancy.id
                                    );
                                    setManageEmployeeAction(
                                      EmployeeStatus.APPROVED
                                    );
                                    setOpenDialogApproveEmployee(true);
                                    setDialogTitle("aprobar");
                                    setApplyId(
                                      record.applyId ? record.applyId : ""
                                    );
                                  }}
                                >
                                  <ThumbUpOffAltOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setManageEmployeeId(
                                      record.id ? record.id : record.vacancy.id
                                    );
                                    setManageEmployeeAction(
                                      EmployeeStatus.REJECTED
                                    );
                                    setOpenDialogApproveEmployee(true);
                                    setDialogTitle("rechazar");
                                    setApplyId(
                                      record.applyId ? record.applyId : ""
                                    );
                                  }}
                                >
                                  <ThumbDownOffAltOutlined />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          ) : headCell.id === "legalRepresentative.name" ? (
                            <TableCell>
                              <Typography>
                                {`${record.legalRepresentative?.name} ${
                                  record.legalRepresentative?.lastname ?? ""
                                }`.trim()}
                              </Typography>
                            </TableCell>
                          ) : (
                            <>
                              {["status", "vacancy.status"].includes(
                                headCell.id
                              ) ? (
                                <TableCell key={headCell.id}>
                                  <ChipStatus
                                    label={
                                      EmployeeStatus[
                                        getNestedValues(headCell.id, record)
                                      ]
                                    }
                                    setcolor={getNestedValues(
                                      headCell.id,
                                      record
                                    )}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell key={headCell.id}>
                                  <Typography>
                                    {getNestedValues(headCell.id, record)}
                                  </Typography>
                                </TableCell>
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </TableContainer>
      </Stack>
      <AlertSnackBar
        open={errorReport.isOpen}
        message={errorReport.message}
        handleClose={errorReport.handleClose}
      />
      <Dialogs
        open={openDialogApproveEmployee}
        handleClose={handleCloseDialogDeleteEmployee}
        handleConfirmation={handleApproveEmployee}
        isLoading={manageEmployeeIsLoading}
        title={`¿Está seguro que desea ${dialogTitle} esta postulación?`}
      />
    </>
  );
}
