import { ROLES } from "../constants/roles";
import { includesSome } from "./array";
import { services } from "../constants/access";

export const hasRole = (session, role) => {
  const auth =
    session?.authorization.find((auth) => auth.service === services.CURRENT) ||
    {};

  if (role === ROLES.PROVIDER_TMS) {
    return (
      auth.roles?.includes(ROLES.PROVIDER_TMS) &&
      !includesSome(auth?.roles, [ROLES.FLEET_TMS, ROLES.SECURITY_TMS])
    );
  }

  return auth.roles?.includes(role);
};
