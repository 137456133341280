import { useSession } from "@blue-express/bx-lib-universal-frontend";
import {
  fetchSessionFunctionalities,
  checkPermission,
} from "../utils/permissions";

export const useAuth = () => {
  const { getSession, forceRefreshSession } = useSession();
  const session = getSession();
  const permissions = fetchSessionFunctionalities(session);

  const validatePermissions = (permission) => {
    return checkPermission(permissions, permission);
  };

  const sessiondata = {
    idToken: session?.idToken ?? "",
    sessionid: session?.sessionid ?? "",
    username: session?.username ?? "",
    firstname: session?.firstname ?? "",
    lastname: session?.lastname ?? "",
    avatar: session?.avatar ?? "",
    userType: session?.userType ?? "",
    authId: session?.authId ?? "",
    ssoToken: session?.ssoToken ?? "",
    ssoRefresh: session?.ssoRefresh ?? "",
    createdAt: session?.createdAt ?? "",
    authorization: session?.authorization ?? [],
    email: session?.username ?? "",
  };

  return {
    ...sessiondata,
    isAuth: session !== null,
    forceRefreshSession,
    validatePermissions,
  };
};
