export const buildUrlWithQueryParams = (url = "", obj = {}) => {
  const urlAux = new URL(url);
  Object.entries(obj).forEach(([key, value]) =>
    urlAux.searchParams.set(key, value)
  );

  return urlAux.toString();
};

export const objectToTypeOptionList = (object) => {
  const options = Object.keys(object).map((key) => {
    return {
      id: key,
      name: object[key],
      value: key,
    };
  });
  return options;
};
